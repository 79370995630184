import 'bootstrap'
import 'slick-carousel'
import './images/favicon.ico'
import './sass/main.scss'

$(window).on("load", function() {
	$("div").is(".page-loader") && $(".page-loader").delay(500).fadeOut(800);
});

$(window).on("scroll", function() {
	if($(this).scrollTop() > 50) {
		$(".navbar").removeClass("navbar-dark").addClass("navbar-light bg-light");
	} else {
		$(".navbar").addClass("navbar-dark").removeClass("navbar-light bg-light");
	}
	$(this).scrollTop() > 150 ? $(".scroll-top").addClass("scroll-top-visible") : $(".scroll-top").removeClass("scroll-top-visible");
});

$(document).ready(function(){
	$('#partners').slick({
		infinite: true,
		arrows: false,
		slidesToShow: 6,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
	});	

	// Contact Form Fields
	var hidden_fields = '<input type="url" class="shpot" name="_shpot" value="" />';
	var visitor_id = '';
	
	hidden_fields += '<input type="hidden" name="_timeindex" value="' + Math.round(new Date().getTime() / 1000) + '" />';
	hidden_fields += '<input type="hidden" name="_referrer" value="' + encodeURIComponent(document.referrer) + '" />';
	_paq.push([ function() { 
		visitor_id = this.getVisitorId();
		hidden_fields += '<input type="hidden" name="_visitor_id" value="' + visitor_id + '" />';
	}]);
	$('#contact-form').prepend(hidden_fields);

	// Contact Form Submission
	$('#contact-form').submit(function(e){
		e.preventDefault();
		var thisForm = $(this);
		$('.sr-overlay', thisForm).css('visibility', 'visible');
		
		_paq.push(['trackEvent', 'Marketing', 'Lead Form', 'contact-form-submit']);
		
		$.ajax({
			type: 'POST',
			data: JSON.stringify(thisForm.serializeArray()),
			contentType: 'application/json',
			url: '/sendForm',						
			success: function(data) {
				$('.spinner-border', thisForm).remove();
				$('.sr-overlay-msg', thisForm).text('Thanks for reaching out. Your message has been sent to our team.');
				//$('.sr-overlay', thisForm).delay(3000).css('visibility', 'hidden');
			},
			error: function(xhr, ajaxOptions, thrownError) {
				$('.spinner-border', thisForm).remove();
				$('.sr-overlay-msg', thisForm).text('We apologize, our form is not available at this time. You may contact us at secure@methodintelligence.com');
			}
		});
	});
});
